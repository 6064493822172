import React from "react"
import PropTypes from "prop-types"
import { useThemeUI } from "theme-ui"

import Box from "./box"
import Grid from "./grid"

const PreviewPad = ({ season }) => (
  <Box
    sx={{
      width: "100%",
      pt: "100%",
      bg: season || "padInactive",
      borderRadius: "1px",
    }}
  ></Box>
)

const SetPreview = ({ isActive, pads, ...restProps }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Box
      as="button"
      sx={{
        appearance: "none",
        bg: "muted",
        borderColor: "background",
        borderRadius: "lg",
        borderStyle: "solid",
        borderWidth: 2,
        boxShadow: isActive ? `0 0 0 4px ${theme.colors["contrast"]}` : null,
        p: [2, 3],
        width: [16, 24],
        variant: "buttons.pads",
        ":focus": {
          outline: 0,
          boxShadow: `0 0 0 4px ${theme.colors["contrast"]}`,
        },
      }}
      {...restProps}
    >
      <Grid columns={[4]} space={1}>
        {pads.map(({ season }, i) => (
          <PreviewPad key={`pad-${i}`} season={season} />
        ))}
      </Grid>
    </Box>
  )
}

SetPreview.propTypes = {
  isActive: PropTypes.bool,
}

export default SetPreview

import metadataUlrike from "./ulrike.json"
import soundUlrike from "./ulrike.mp3"
import metadataChantal from "./chantal.json"
import soundChantal from "./chantal.mp3"
import metadataChristian from "./christian.json"
import soundChristian from "./christian.mp3"

export const soundMetadata = {
  spring: [
    { soundId: "spring-01", title: "Der Frühling" },
    {
      soundId: "spring-02",
      title: "Der Mensch vergisst die Sorgen aus dem Geiste",
    },
    {
      soundId: "spring-03",
      title: "Der Frühling aber blüht, und prächtig ist das Meiste",
    },
    {
      soundId: "spring-04",
      title: "Das grüne Feld ist herrlich ausgebreitet",
    },
    {
      soundId: "spring-05",
      title: "Da glänzend schön der Bach hinuntergleitet",
    },
    {
      soundId: "spring-06",
      title: "Die Berge stehn bedeket mit den Bäumen",
    },
    {
      soundId: "spring-07",
      title: "Und herrlich ist die Luft in offnen Räumen",
    },
    {
      soundId: "spring-08",
      title: "Das weite Thal ist in der Welt gedehnet",
    },
    {
      soundId: "spring-09",
      title: "Und Thurm und Haus an Hügeln angelehnet",
    },
    { soundId: "spring-10", title: "Mit Untertänigkeit" },
    { soundId: "spring-11", title: "Scardanelli" },
  ],
  summer: [
    { soundId: "summer-01", title: "Der Sommer" },
    {
      soundId: "summer-02",
      title: "Das Erndtefeld erscheint, auf Höhen schimmert",
    },
    {
      soundId: "summer-03",
      title: "Der hellen Wolke Pracht, indeß am weiten Himmel",
    },
    {
      soundId: "summer-04",
      title: "In stiller Nacht die Zahl der Sterne flimmert,",
    },
    {
      soundId: "summer-05",
      title: "Groß ist und weit von Wolken das Gewimmel.",
    },
    {
      soundId: "summer-06",
      title: "Die Pfade gehn entfernter hin, der Menschen Leben",
    },
    { soundId: "summer-07", title: "Es zeiget sich auf Meeren unverborgen," },
    {
      soundId: "summer-08",
      title: "Der Sonne Tag ist zu der Menschen Streben",
    },
    {
      soundId: "summer-09",
      title: "Ein hohes Bild, und golden glänzt der Morgen.",
    },
    {
      soundId: "summer-10",
      title: "Mit neuen Farben ist geschmückt der Gärten Breite,",
    },
    {
      soundId: "summer-11",
      title: "Der Mensch verwundert sich, daß sein Bemühn gelinget,",
    },
    {
      soundId: "summer-12",
      title: "Was er mit Tugend schafft, und was er hoch vollbringet,",
    },
    {
      soundId: "summer-13",
      title: "Es steht mit der Vergangenheit in prächtigem Geleite.",
    },
  ],
  autumn: [
    { soundId: "autumn-01", title: "Der Herbst." },
    {
      soundId: "autumn-02",
      title: "Das Glänzen der Natur ist höheres Erscheinen,",
    },
    {
      soundId: "autumn-03",
      title: "Wo sich der Tag mit vielen Freuden endet,",
    },
    {
      soundId: "autumn-04",
      title: "Es ist das Jahr, das sich mit Pracht vollendet,",
    },
    {
      soundId: "autumn-05",
      title: "Wo Früchte sich mit frohem Glanz vereinen.",
    },
    {
      soundId: "autumn-06",
      title: "Das Erdenrund ist so geschmükt, und selten lärmet",
    },
    {
      soundId: "autumn-07",
      title: "Der Schall durchs offne Feld, die Sonne wärmet",
    },
    {
      soundId: "autumn-08",
      title: "Den Tag des Herbstes mild, die Felder stehen",
    },
    { soundId: "autumn-09", title: "Als eine Aussicht weit, die Lüffte wehen" },
    {
      soundId: "autumn-10",
      title: "Die Zweig’ und Äste durch mit frohem Rauschen",
    },
    {
      soundId: "autumn-11",
      title: "Wenn schon mit Leere sich die Felder dann vertauschen,",
    },
    { soundId: "autumn-12", title: "Der ganze Sinn des hellen Bildes lebet" },
    {
      soundId: "autumn-13",
      title: "Als wie ein Bild, das goldne Pracht umschwebet.",
    },
    { soundId: "autumn-14", title: "d. 15ten Nov. 1759." },
  ],
  winter: [
    { soundId: "winter-01", title: "Der Winter." },
    {
      soundId: "winter-02",
      title: "Wenn sich das Laub auf Ebnen weit verloren,",
    },
    {
      soundId: "winter-03",
      title: "So fällt das Weiß herunter auf die Thale,",
    },
    {
      soundId: "winter-04",
      title: "Doch glänzend ist der Tag vom hohen Sonnenstrale,",
    },
    {
      soundId: "winter-05",
      title: "Es glänzt das Fest den Städten aus den Thoren.",
    },
    {
      soundId: "winter-06",
      title: "Es ist die Ruhe der Natur, des Feldes Schweigen",
    },
    {
      soundId: "winter-07",
      title: "Ist wie des Menschen Geistigkeit, und höher zeigen",
    },
    {
      soundId: "winter-08",
      title: "Die Unterschiede sich, daß sich zu hohem Bilde",
    },
    {
      soundId: "winter-09",
      title: "Sich zeiget die Natur, statt mit des Frühlings Milde.",
    },
    { soundId: "winter-10", title: "d. 25 Dezember 1841" },
    { soundId: "winter-11", title: "Dero unterthänigster" },
    { soundId: "winter-12", title: "Scardanelli." },
  ],
}

const improveSprites = (sprites) =>
  Object.keys(sprites).reduce((acc, curr) => {
    acc[curr] = [sprites[curr][0], sprites[curr][1] + 200]
    return acc
  }, {})

export const sprites = {
  chantal: improveSprites(metadataChantal.sprite),
  christian: improveSprites(metadataChristian.sprite),
  ulrike: improveSprites(metadataUlrike.sprite),
}

export const speakerExtras = {
  chantal: { volume: 1.7 },
  christian: { volume: 0.7 },
  ulrike: { volume: 1.1 },
}

export const getSounds = ({ soundHook, loadedCallback }) => {
  // number of speakers is static, make sure to change that
  const numberOfSounds = 3
  let loadedSounds = 0
  const hook = soundHook ? soundHook : (url) => url

  const getHookOptions = ({ speaker }) => {
    return {
      interrupt: false,
      onload: () => {
        loadedSounds = loadedSounds + 1
        if (loadedSounds >= numberOfSounds && loadedCallback) {
          loadedCallback()
        }
      },
      sprite: sprites[speaker],
      ...speakerExtras[speaker],
    }
  }

  return {
    chantal: hook(soundChantal, getHookOptions({ speaker: "chantal" })),
    christian: hook(soundChristian, getHookOptions({ speaker: "christian" })),
    ulrike: hook(soundUlrike, getHookOptions({ speaker: "ulrike" })),
  }
}

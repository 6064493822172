import { useState, useEffect } from "react"
import useSound from "use-sound"
import produce from "immer"
import { soundMetadata, getSounds, sprites } from "./sounds"

const gobalWindow = typeof window !== "undefined" && window
const defaultSpeaker = "ulrike"

const getStateFromLocalStorage = () => {
  return gobalWindow
    ? JSON.parse(gobalWindow.localStorage.getItem("soundboardState"))
    : null
}

const setStateFromLocalStorage = ({ state }) => {
  if (gobalWindow) {
    localStorage.setItem("soundboardState", JSON.stringify(state))
  }
}

const getPrefilledState = () => {
  const numberOfSets = 4
  const numberOfPads = 8
  const state = Array(numberOfSets).fill(
    Array(numberOfPads).fill({
      speaker: defaultSpeaker,
      season: null,
      soundId: null,
    })
  )

  const prefilledState = produce(state, (draftState) => {
    draftState[0][0] = {
      speaker: "ulrike",
      season: "spring",
      soundId: "spring-01",
    }
    draftState[0][1] = {
      speaker: "christian",
      season: "summer",
      soundId: "summer-01",
    }
    draftState[0][2] = {
      speaker: "chantal",
      season: "autumn",
      soundId: "autumn-01",
    }
    draftState[0][3] = {
      speaker: "ulrike",
      season: "winter",
      soundId: "winter-01",
    }
    draftState[0][4] = {
      speaker: "christian",
      season: "spring",
      soundId: "spring-02",
    }
  })

  return prefilledState
}

const getInitialState = () => {
  const storedState = getStateFromLocalStorage()
  if (storedState) {
    return storedState
  }
  return getPrefilledState()
}

export const useSoundboard = () => {
  const [soundsLoaded, setSoundsLoaded] = useState(false)
  const sounds = getSounds({
    soundHook: useSound,
    loadedCallback: () => {
      setSoundsLoaded(true)
    },
  })
  const [state, setState] = useState(getInitialState())
  const [selectedSet, setSelectedSet] = useState(0)
  const [selectedPad, setSelectedPad] = useState(0)

  useEffect(() => {
    setStateFromLocalStorage({ state })
  }, [state])

  const getSound = ({ speaker }) => {
    if (!speaker) {
      return null
    }
    return sounds[speaker]
  }

  const getSoundMetadata = ({ season, soundId }) => {
    return soundMetadata[season]?.find((sound) => sound.soundId === soundId)
  }

  const getPadData = ({ speaker, season, soundId }) => {
    if (!speaker || !season || !soundId) {
      return {
        speaker,
        season,
      }
    }
    const metadata = getSoundMetadata({ season, soundId })

    return {
      speaker,
      season,
      ...metadata,
      duration: sprites[speaker][soundId][1],
    }
  }

  const getSelectedPadData = () => {
    const padState = state[selectedSet][selectedPad]
    return getPadData(padState)
  }

  const setSelectedPadSound = ({ season, soundId }) => {
    const draft = produce(state, (draftState) => {
      draftState[selectedSet][selectedPad].season = season
      draftState[selectedSet][selectedPad].soundId = soundId
    })
    setState(draft)
  }

  const setSelectedPadSpeaker = ({ speaker }) => {
    const draft = produce(state, (draftState) => {
      draftState[selectedSet][selectedPad].speaker = speaker
    })
    setState(draft)
  }

  const playPad = ({ padNumber }) => {
    const { speaker, soundId } = state[selectedSet][padNumber]
    if (!speaker || !soundId) {
      return
    }
    const sound = getSound({ speaker })
    if (!sound) {
      return null
    }

    // stop all other sounds
    for (const pad of state[selectedSet]) {
      const stopSound = getSound({ speaker: pad.speaker })
      const stop = stopSound[1]?.stop
      if (stop) {
        stop()
      }
    }

    const [play] = sound
    play({ id: soundId })
  }

  return {
    playPad,
    sets: state,
    pads: state[selectedSet].map((padState) => getPadData(padState)),
    soundMetadata,
    selectedPad: {
      padNumber: selectedPad,
      data: getSelectedPadData(),
    },
    setSelectedPad,
    selectedSet,
    setSelectedSet,
    setSelectedPadSound,
    setSelectedPadSpeaker,
    soundsLoaded,
  }
}

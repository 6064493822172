import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Box from "./box"
import Icon from "./icon"

const SoundEditButton = ({
  icon,
  children,
  isDisabled,
  color,
  href,
  onClick,
  to,
  ...restProps
}) => {
  const buttonColor = isDisabled ? "disabled" : "contrast"

  return (
    <Box
      as={to ? Link : href ? "a" : onClick ? "button" : "span"}
      to={to || null}
      href={href || null}
      onClick={onClick || null}
      sx={{
        appearance: "none",
        bg: buttonColor,
        cursor: isDisabled ? "not-allowed" : "pointer",
        textAlign: "center",
        color: isDisabled ? buttonColor : "background",
        borderRadius: "9999em",
        py: [null, 3],
        px: [null, 4],
        fontFamily: "sans",
        fontWeight: "bold",
        display: "inline-flex",
        width: [12, "auto"],
        height: [12, "auto"],
        alignItems: "center",
        justifyContent: "center",
        variant: "buttons.pads",
        ":hover, :focus": isDisabled
          ? null
          : {
              bg: "contrastHover",
            },
      }}
      {...restProps}
    >
      {icon && (
        <Box>
          <Icon icon={icon} color="background" />
        </Box>
      )}
      <Box
        as="div"
        className="bubble-button-text"
        sx={{
          display: ["none", "inline"],
          ml: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

SoundEditButton.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

SoundEditButton.defaultProps = {}

export default SoundEditButton

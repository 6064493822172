import { Link } from "gatsby"
import React from "react"

import Box from "./box"
import Column from "./column"
import Columns from "./columns"
import ThemeToggle from "./theme-toggle"

const NavLink = ({ to, sx, children, ...restProps }) => {
  return (
    <Box
      as={Link}
      to={to}
      sx={{
        fontFamily: "sans",
        fontWeight: "bold",
        "&:hover, &:focus": {
          color: "primary",
        },
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

const SoundboardHeader = () => {
  return (
    <Box as="header" sx={{ flexShrink: 0, p: [3, 4] }}>
      <Columns alignY="center" space={1}>
        <Column>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <NavLink to={"/"}>Hölderlinturm</NavLink>
            <Box
              as="p"
              sx={{
                fontFamily: "sans",
                ml: 2,
                display: ["none", "inline-block"],
              }}
            >
              Jahreszeiten-Gedicht-Maschine
            </Box>
          </Box>
        </Column>
        <Column width="content">
          <ThemeToggle />
        </Column>
      </Columns>
    </Box>
  )
}

export default SoundboardHeader

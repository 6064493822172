/**
 * Layout component for the soundboard
 */

import React from "react"
import PropTypes from "prop-types"

import Box from "../components/box"
import SoundboardHeader from "../components/soundboard-header"
import Preflight from "../gatsby-plugin-theme-ui/preflight"
import SkipContent from "../components/skip-content"

const LayoutSoundboard = ({
  as,
  children,
  footer,
  navigation,
  ...restProps
}) => {
  return (
    <>
      <Preflight />
      <SkipContent />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          // minHeight: "-webkit-fill-available", while this fixes mobile safaris overflow issue, this breaks chrome
          width: "100%",
          flexDirection: "column",
        }}
      >
        <SoundboardHeader />
        <Box
          as="main"
          id="inhalt"
          sx={{
            flex: "1 1 auto",
            px: [3, 4],
            display: "flex",
            width: "100%",
            height: "100%",
            maxWidth: "7xl",
            flexDirection: "column",
            my: "auto",
            mx: "auto",
          }}
        >
          {children}
        </Box>
        {footer && (
          <Box
            sx={{
              p: [3, 4],
              flexShrink: 0,
            }}
          >
            {footer}
          </Box>
        )}
        {/* <Box
          sx={{
            height: "100%",
            width: "100%",
            maxWidth: "8xl",
            flexDirection: "column",
            display: "flex",
          }}
        >
        </Box> */}
      </Box>
    </>
  )
}

LayoutSoundboard.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  navigation: PropTypes.object,
}

export default LayoutSoundboard

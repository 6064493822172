import React, { useEffect, useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../layouts/soundboard"
import Seo from "../components/seo"
import SetPreview from "../components/set-preview"
import SoundPad from "../components/sound-pad"
import SoundEditButton from "../components/sound-edit-button"
import Grid from "../components/grid"
import Inline from "../components/inline"
import Stack from "../components/stack"
import Box from "../components/box"
import LoadingPulse from "../components/loading-pulse"
import { useSoundboard } from "../content/jahreszeiten-gedicht-maschine/soundboard"
import SoundSelectionModal from "../content/jahreszeiten-gedicht-maschine/sound-selection-modal"

const speakers = {
  ulrike: {
    title: "Sprecher 1",
    icon: "speaker-1",
  },
  chantal: {
    title: "Sprecher 2",
    icon: "speaker-2",
  },
  christian: {
    title: "Sprecher 3",
    icon: "speaker-3",
  },
}

const JahreszeitenGedichtMaschine = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "og_images/soundboard.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
      }
    `
  )

  const {
    playPad,
    pads,
    sets,
    soundMetadata,
    selectedPad,
    setSelectedPad,
    selectedSet,
    setSelectedSet,
    setSelectedPadSound,
    setSelectedPadSpeaker,
    soundsLoaded,
  } = useSoundboard()
  const [soundSelectionVisible, setSoundSelectionVisible] = useState(false)
  const [lastClicks, setLastClicks] = useState([])

  const onClickPad = useCallback(
    ({ padNumber }) => {
      setSelectedPad(padNumber)
      playPad({ padNumber })
      const newState = []
      newState[padNumber] = Date.now()
      setLastClicks(newState)
    },
    [setSelectedPad, playPad, setLastClicks]
  )

  useEffect(() => {
    const handleKeyPress = (event) => {
      const capturedKeys = ["1", "2", "3", "4", "5", "6", "7", "8"]
      if (capturedKeys.includes(event.key)) {
        event.preventDefault()
        const padNumber = parseInt(event.key) - 1
        onClickPad({ padNumber })
      }
    }
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [selectedPad, onClickPad])

  const constClickSet = ({ setNumber }) => {
    setSelectedSet(setNumber)
    setLastClicks([])
    setSelectedPad(0)
  }

  const onOpenSoundSelection = () => {
    setSoundSelectionVisible(true)
  }

  const onCloseSoundSelection = () => {
    setSoundSelectionVisible(false)
  }

  const onChangePadSelection = ({ season, soundId }) => {
    setSelectedPadSound({ season, soundId })
    setSoundSelectionVisible(false)
  }

  const onChangeSpeaker = () => {
    const speakerKeys = Object.keys(speakers)
    const speakerIndex = speakerKeys.findIndex(
      (key) => key === selectedPad.data.speaker
    )
    const speaker = speakerKeys[(speakerIndex + 1) % speakerKeys.length]
    setSelectedPadSpeaker({ speaker })
  }

  return (
    <Layout
      footer={
        soundsLoaded ? (
          <Inline alignX="center" alignY="center" space={[2, 3]}>
            {sets.map((pads, setNumber) => (
              <SetPreview
                key={`set-preview-${setNumber}`}
                pads={pads}
                isActive={selectedSet === setNumber}
                onClick={(event) => {
                  event.preventDefault()
                  constClickSet({ setNumber })
                }}
              />
            ))}
          </Inline>
        ) : null
      }
    >
      <Seo
        title="Jahreszeiten-Gedicht-Maschine"
        description="Mix dir dein eigenes Jahreszeiten-Gedicht, indem du Versatzstücke aus 4 verschiedenen Gedichten kombinierst."
        image={file.childImageSharp.gatsbyImageData}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          my: "auto",
        }}
      >
        {soundsLoaded ? (
          <>
            <SoundSelectionModal
              isOpen={soundSelectionVisible}
              onClose={onCloseSoundSelection}
              contentLabel="Sound Selection"
              selectedPad={selectedPad.padNumber}
              sounds={soundMetadata}
              selectedSound={selectedPad.data.soundId}
              selectedSeason={selectedPad.data.season}
              onChangeSelection={onChangePadSelection}
            />
            <Stack space={6}>
              <Inline alignX="center" alignY="center">
                <SoundEditButton icon="edit" onClick={onOpenSoundSelection}>
                  Sound wählen
                </SoundEditButton>
                <SoundEditButton
                  icon={speakers[selectedPad.data.speaker].icon}
                  onClick={onChangeSpeaker}
                >
                  {speakers[selectedPad.data.speaker].title}
                </SoundEditButton>
              </Inline>
              <Grid columns={[4]} space={3}>
                {pads.map(({ title, speaker, season, duration }, padNumber) => (
                  <SoundPad
                    key={`pad-${padNumber}`}
                    id={padNumber + 1}
                    title={title}
                    speakerIcon={speakers[speaker].icon}
                    season={season}
                    isActive={selectedPad.padNumber === padNumber}
                    duration={duration}
                    currentClick={lastClicks[padNumber]}
                    onClick={(event) => {
                      event.preventDefault()
                      onClickPad({ event, padNumber })
                    }}
                  />
                ))}
              </Grid>
            </Stack>
          </>
        ) : (
          <Inline alignX="center" alignY="center">
            <LoadingPulse />
          </Inline>
        )}
      </Box>
    </Layout>
  )
}

export default JahreszeitenGedichtMaschine

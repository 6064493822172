import React from "react"
import { useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { lighten } from "@theme-ui/color"

import Box from "./box"
import Columns from "./columns"
import Column from "./column"
import Text from "./text"
import Icon from "./icon"
import SoundPadProgress from "./sound-pad-progress"

const SoundPad = ({
  title,
  id,
  season,
  speakerIcon,
  isActive,
  duration,
  currentClick,
  ...restProps
}) => {
  const context = useThemeUI()
  const { theme } = context

  const padColor = season || "padInactive"

  return (
    <Box
      as="button"
      sx={{
        appearance: "none",
        textAlign: "left",
        width: "100%",
        backgroundColor: padColor,
        backgroundImage: (theme) => `
          radial-gradient(
            circle,
            ${lighten(padColor, 0.1)(theme)},
            ${lighten(padColor, 0)(theme)}
          )
        `,
        paddingTop: ["100%", 0],
        height: ["auto", 24, 40, 48, 56, 64],
        borderRadius: "lg",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "background",
        boxShadow: isActive ? `0 0 0 4px ${theme.colors["contrast"]}` : null,
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        variant: "buttons.pads",
        ":focus": {
          outline: 0,
          boxShadow: `0 0 0 4px ${theme.colors["contrast"]}`,
        },
      }}
      {...restProps}
    >
      {currentClick && (
        <SoundPadProgress duration={duration} currentClick={currentClick} />
      )}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          color: "black",
          right: 0,
          bottom: 0,
          top: 0,
          p: [2, 3, 3, 6],
        }}
      >
        <Box
          sx={{
            flex: "1 0 auto",
            position: "relative",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: ["none", "block"],
            }}
          >
            <Text color="backround" sans>
              {title}
            </Text>
          </Box>
        </Box>
        <Box sx={{ position: "relative", zIndex: 10 }}>
          <Columns alignY="center" alignX="space-between">
            <Column>
              <Text color="backround" sans bold>
                {id}
              </Text>
            </Column>
            <Column width="content">
              <Icon icon={speakerIcon}></Icon>
            </Column>
          </Columns>
        </Box>
      </Box>
    </Box>
  )
}

SoundPad.propTypes = {
  /** defines the color in which the pad get's rendered. we've got a color in our theme for each season */
  season: PropTypes.string,
  /** title to render */
  title: PropTypes.string,
  /** speaker. this changes the sound file and icon */
  speaker: PropTypes.string,
  /** id is used for consecutive numbering */
  id: PropTypes.number,
  isActive: PropTypes.bool,
}

SoundPad.defaultProps = {
  title: "Nichts ausgewählt",
  number: 1,
}

export default SoundPad
